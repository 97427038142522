@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.button,
.button:focus,
.button:active {
  width: 32px;
  height: 56px;
  background: $white;
  border: 1px solid $bright_grey;
  box-shadow: 0 3px 5px 1px $black_20;
  border-radius: 4px;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
}

.nextButton {
  transform: rotate(180deg);
}
